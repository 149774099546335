import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, notification, Spin, Upload, UploadFile, UploadProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Plus } from "lucide-react";

import { RootState } from "../../store/store";

import Cookies from "js-cookie";

import { NotificationType } from "../../types/commonTypes";
import { FilesServices } from "../../services/apis/filesServices";
import { Formatters } from "./domains/formatters";
import { DatesWithHours } from "./selectDates";
import { EventsServices } from "../../services/apis/eventsServices";
import { fetchEventById } from "../../store/eventSlice";
import styles from "./events.module.scss";
import moment from "moment";

const { TextArea } = Input;

interface IEditable {
  visible: boolean;
  eventId: number | null;
}

interface NewEventProps {
  visible: boolean;
  idEvent: number | null;
  setVisible: React.Dispatch<React.SetStateAction<IEditable>>;
  getEvents: () => void;
}

export function UpdateEvent({ 
  visible, 
  setVisible,
  idEvent,
  getEvents
}: Readonly<NewEventProps>) {
  const currentWidth = window.innerWidth;
  const userToken = Cookies.get('access_token');
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [arrayDates, setArrayDates] = useState<any[]>(['']);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const event = useSelector((state: RootState) => state.eventSlice.event);
  const status = useSelector((state: RootState) => state.eventSlice.status);
  const [api, contextHolder] = notification.useNotification();

  const filesServices = new FilesServices();

  const imcrementDates = () => {
    setArrayDates([...arrayDates, '']);
  }

  const removeDate = (index: number) => {
    if (arrayDates.length === 1) {
      return;
    }
    const newArray = arrayDates.filter((_, i) => i !== index);
    setArrayDates(newArray);
  }

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  useEffect(() => {
    if (idEvent) {
        dispatch(fetchEventById(idEvent.toString()) as any);
    }
  }, [idEvent, dispatch]);

  useEffect(() => {
    if (event && status === 'idle') {
      const initialValues: { [key: string]: any } = {
        title: event.title,
        description: event.description,
        movement: event.movement,
        location: event.location,
        accessLink: event.access_link,
      };

      event.dates.forEach((date: any, index: number) => {
          initialValues[`initialDate${index}`] = moment(date.start).format('YYYY-MM-DD');
          initialValues[`initialTime${index}`] = moment(date.start).format('HH:mm');
          initialValues[`endTime${index}`] = moment(date.end).format('HH:mm');
      });

      form.setFieldsValue(initialValues);
      setArrayDates(event.dates);
    }
}, [event, status, form]);

useEffect(() => {
  if (event && event.files && event.files.length > 0) {
    const adaptedFiles = event.files.map((file: any) => ({
      uid: file.id.toString(),
      name: file.name_document,
      status: 'done',
      url: file.url_file,
      response: { 
        fileName: file.name_document, 
        id_bucket: file.id_bucket
      },
    }));
    setFileList(adaptedFiles);
  }
}, [event]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const formatters = new Formatters();
    const eventService = new EventsServices();
    try {
      const dates: any = [];
      const formattedValues = Object.keys(values);
      for (let i = 0; i < formattedValues.length; i++) {
        if (formattedValues[i].includes('initialDate')) {
          const index = formattedValues[i].split('initialDate')[1];
          const startDate = new Date(values[`initialDate${index}`] + 'T00:00:00'); // Adiciona T00:00:00 para garantir que a data é criada corretamente
  
          const startDateTime = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            parseInt(values[`initialTime${index}`].split(':')[0]),
            parseInt(values[`initialTime${index}`].split(':')[1])
          );
  
          const endDateTime = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            parseInt(values[`endTime${index}`].split(':')[0]),
            parseInt(values[`endTime${index}`].split(':')[1])
          );
  
          const date = {
            start: startDateTime.toISOString(),
            end: endDateTime.toISOString()
          };
          dates.push(date);
        }
      }
      const formattedEvent = formatters.formatEvent({...values, id_initial: event.id_initial, dates});
      await eventService.updateEvent(event.id ,formattedEvent);
      setLoading(false);
      openNotificationWithIcon(
        "success",
        "Evento atualizado",
        "Evento atualizado com sucesso"
      );
      setVisible({
        visible: false,
        eventId: null,
      });
      getEvents();
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Erro ao criar evento",
        `${error.message} - Tente novamente`
      );
      
    }
  };

  const deleteFile = async (idBucket: string) => {
    try {
      const response = await filesServices.deleteAttachmentEvent(idBucket);
      getEvents();
      return response;
    } catch (error) {
      return;
    }
  }

  const handleUpload: UploadProps = {
    // action: 'http://localhost:4001/files/upload/attachments-events', //local
    action: 'https://orca-app-so9hc.ondigitalocean.app/files/upload/attachments-events', //stage
    headers: {
      Authorization: `Bearer ${userToken}`,
      'X-Custom-Header': event?.id_initial,
    },
    onChange(info) { 
      // Atualizar fileList imediatamente para refletir o status de upload.
      setFileList(info.fileList.map(file => ({
        ...file,
        url: file.response?.url // Certifique-se de que o URL é acessível no objeto de resposta
      })));
    
      if (info.file.status === 'done') {
        openNotificationWithIcon("success", "Arquivo enviado", `${info.file.name} enviado com sucesso`);
      } else if (info.file.status === 'error') {
        openNotificationWithIcon("error", "Falha no envio", `${info.file.name} falhou ao enviar`);
      }
    },

    onRemove(file: UploadFile) {
      // A suposição é que a informação necessária para deletar o arquivo está dentro de `file.response`
      const idBucket = file.response?.id_bucket; // Adapte conforme a estrutura de sua resposta
      if (!idBucket) {
        openNotificationWithIcon("error", "Erro ao deletar", "Não foi possível deletar o arquivo [id_bucket não encontrado]");
        return;
      }
    
      deleteFile(idBucket).then(() => {
        setFileList(prevFiles => prevFiles.filter(f => f.uid !== file.uid));
        openNotificationWithIcon("success", "Arquivo deletado", "Arquivo deletado com sucesso");
      }).catch(error => {
        openNotificationWithIcon("error", "Erro ao deletar", "Não foi possível deletar o arquivo");
      });
    }
  };


  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Modal
      title="Criar Evento"
      open={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        setVisible({
          visible: false,
          eventId: null,
        });
      }}
      width={currentWidth < 768 ? "100%" : "50%"}
    >
      {contextHolder}
      <Form
        className={styles.formContainer}
        onFinish={onFinish}
        form={form}
        layout="vertical"
      >
        <Form.Item
          className={styles.formItem}
          label="Título"
          name="title"
          rules={[{ required: true, message: "Campo título é obrigatório" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          label="Descrição"
          name="description"
          rules={[{ required: true, message: "Campo descrição é obrigatório" }]}
        >
          <TextArea />
        </Form.Item>
        <div className={styles.formRow}>
          <Form.Item
            className={styles.formItem}
            label="Realizador"
            name="movement"
            rules={[
              { required: true, message: "Campo movimento é obrigatório" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label="Local previsto"
            name="location"
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          className={styles.formItem}
          label="Link de acesso"
          name="accessLink"
        >
          <Input />
        </Form.Item>

        {arrayDates.map((_, index) => (
          <DatesWithHours 
            key={index} 
            removeDate={removeDate} 
            index={index}
            defaultValue={event?.dates}
          />
        ))}
        <Button 
          type="primary" 
          icon={<Plus />} 
          onClick={imcrementDates}
          style={{
            width: 35,
            height: 35,
            marginTop: '-0.5rem',
            marginBottom: '1.5rem',
          }}
        />

        <div>Imagens do evento</div>
        <br />

        <Upload 
          {...handleUpload} 
          multiple 
          listType="picture-card"
          name="files"
          fileList={fileList}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>

        <div className={styles.formButtonContainer}>
          <Form.Item className={styles.formButton}>
            <button
              type="button"
              onClick={() => {
                setVisible({
                  visible: false,
                  eventId: null,
                });
              }}
            >
              Voltar
            </button>
          </Form.Item>
          <Form.Item className={styles.formButton}>
            <Button type="primary" htmlType="submit">
              Atualizar
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Spin spinning={loading} fullscreen />
    </Modal>
  );
}
